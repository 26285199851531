import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../hooks/useApi";
import { ThreeDots } from "react-loader-spinner";
import { IconPlus } from "@tabler/icons-react";
import { HexColorPicker,HexColorInput } from "react-colorful";
import './colourPicker.css'

const StyleManagement = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
	const [assistantName, setAssistantName] = useState("Shyrka");

    useEffect(() => {
        setIsLoading(false);
    });

    const navigate = useNavigate();

    const handleBackpageClick = () => {
        navigate(`/admin/adminhome`);
    };

    if (isLoading)
        return (
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center">
                <div className="text-xl font-semibold text-gray-800 dark:text-gray-400">Loading Style</div>
                <ThreeDots color="#202123" height={110} width={110} wrapperClass="flex justify-center items-center" />
            </div>
        );

    if (error) return <div>Error: {error}</div>;

    return (
        <main className="flex flex-grow h-[calc(100vh-64px)] overflow-y-auto w-screen flex-col text-sm text-white dark:text-white bg-customGrey">
            <button
                className="absolute top-20 left-5 w-24 text-2xl bg-blueButton transition duration-150  hover:bg-slate-300 hover:text-black text-white rounded py-1 px-2 focus:outline-none focus:ring-2 focus:ring-blue-300 z-10"
                onClick={() => handleBackpageClick()}
            >
                &#8592;
            </button>
            <div className="flex justify-center items-center h-full">
                <div className="mr-8 p-5 bg-cardGrey flex flex-col justify-center items-center text-center">
                    <div className="w-full max-w-xs">
					<div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="assistantName">
                                Assistant Name
                            </label>
                            <input defaultValue={assistantName} 
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="assistantName"
                                type="text"
                                placeholder="Shyrka"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="file_input">
                                Logo
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-50"
                                aria-describedby="file_input_help"
                                id="file_input"
                                type="file"
                            />
                            <p className="mt-1 text-sm " id="file_input_help">
                                SVG, PNG, JPG or GIF (MAX. 800x400px).
                            </p>
                        </div>
                        <div className="flex items-center justify-between">
                            <button
                                className="py-2 px-4 transition duration-150 bg-blueButton hover:bg-slate-300 hover:text-black text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-300"
                                type="button"
                            >
                                Preview
                            </button>
                            <button
                                className="py-2 px-4 transition duration-150 bg-green-500 text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300 rounded"
                                type="button"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default StyleManagement;
