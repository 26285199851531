import { Outlet, NavLink, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

import styles from "./Layout.module.css";

import shyrkaLogo from '../../assets/ProjectShyrka_Icon.png';
import { useApi } from '../../hooks/useApi';

interface User {
  email: string;
  is_admin: boolean;
  personas: any[];
}
// @ts-ignore
const CUSTOM_LOGO: any = window.CUSTOM_LOGO;
const showName: boolean = (window as any).SHOW_NAME
// @ts-ignore
const NAME: string = window.NAME;
const customNameSupplied = NAME !== "Shyrka"
console.log(`Name: ${NAME}; Custom Name Supplied: ${customNameSupplied}; ${NAME !== "Shyrka"}`)
// @ts-ignore
document.title = window.NAME
const Layout = () => {
  const { getApiLoggedOnUser } = useApi();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchLoggedOnUser = async () => {
      try {
        const loggedOnUser = await getApiLoggedOnUser();
        setUser(loggedOnUser);
      } catch (error) {
        console.error('Error fetching the logged-on user:', error);
      }
    };
    fetchLoggedOnUser();
  }, []); // Dependency array is empty, so the effect runs only on mount

  return (
    <div className={styles.layout}>
      <header className={styles.header + ' flex-shrink-0'} role={"banner"}>
        <div className={styles.headerContainer}>
          <Link to="/" className={styles.headerTitleContainer}>
            {showName === true ? <h3 className={styles.headerTitle}>{NAME}</h3> : ""}
            {CUSTOM_LOGO == "false" ? <img src={shyrkaLogo} className="h-8 inline pl-2" id="shyrkaLogo" /> : <img src={CUSTOM_LOGO} className="h-8 inline pl-2" id="shyrkaLogo" />}
          </Link>

          {
            // don't show Sundown Solutions if a custom name is supplied
            !customNameSupplied && (
              <h4 className={styles.headerRightText + ' hidden lg:block'}>Sundown Solutions</h4>
            )
          }

          {user && user.is_admin && (
            <Link to="/admin/adminHome" className="text-white font-bold py-2 px-4 rounded transition duration-150 bg-blueButton hover:bg-slate-300 hover:text-black">
              Admin Controls
            </Link>
          )}
        </div>
      </header>

      <Outlet />
    </div>
  );
};

export default Layout;
