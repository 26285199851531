import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route, BrowserRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType, BrowserCacheLocation, PublicClientApplication, Configuration } from "@azure/msal-browser";

import "./index.css";

import Layout from "./pages/layout/Layout";
import LayoutSlim from "./pages/layout/LayoutSlim";

import Chatv2 from "./pages/chatv2/Chatv2";
import AdminHome from "./pages/admin/AdminHome";
import PersonaManagement from "./pages/admin/PersonaAdmin/PersonaManagement";
import UserManagement from "./pages/admin/UserAdmin/UserManagement";
import StyleManagement from "./pages/admin/styleManagement/styleManagement";

// @ts-ignore
const API_AUTH_SCOPE = window.API_AUTH_SCOPE;
// @ts-ignore
const AAD_CLIENT_ID = window.AAD_CLIENT_ID;
// @ts-ignore
const AAD_TENANT_ID = window.AAD_TENANT_ID;

const msalConfig: Configuration = {
    auth: {
        clientId: AAD_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${AAD_TENANT_ID}`,
        redirectUri: "/",
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage // Ensure cache is shared between windows/tabs
    },
    system: {
        allowRedirectInIframe: true
    }
};
const msalInstance = new PublicClientApplication(msalConfig);

initializeIcons();

export default function App() {
    //As we use hashroutes, URLS params need to be handled custom
    let user: string | null = "";
    let defaultPersona: string | null= "";
    let assistantName: string = "";

    if (window.location.href.indexOf("#") === -1) {
        let urlParams = new URLSearchParams(window.location.search);
        user = urlParams.get("user");
        defaultPersona = urlParams.get("persona");
        assistantName = urlParams.get("assistantName") || "";
    } else {
        let hash = window.location.hash.substring(2).replace("slim?", "");
        var result = hash.split("&").reduce(function (res, item) {
            var parts = item.split("=");
            // @ts-ignore
            res[parts[0]] = decodeURI(parts[1]);
            return res;
        }, {});
        // @ts-ignore
        user = result.user;
        // @ts-ignore
        defaultPersona = result.persona;
        // @ts-ignore
        assistantName = result.assistantName;
    }
    console.info(`Persona: ${defaultPersona}`);
    const authRequest = {
        scopes: [API_AUTH_SCOPE]
    };
    if (user) {
        // @ts-ignore
        authRequest.loginHint = user;
    }

    console.log("hash ", window.location.hash);
    console.log("href", window.location.href);

    if (defaultPersona === undefined || defaultPersona === null) {
        // @ts-ignore
        defaultPersona = "Generalist";
    }
    if (assistantName === undefined || assistantName === null || assistantName === "") {
        // @ts-ignore
        assistantName = window.NAME;
    }
    function LoadingComponent() {
        return <p style={{ color: "white" }}>Authentication in progress...</p>;
    }
    function ErrorComponent({ error }: { error: any }) {
        return <p style={{ color: "white" }}>An Error Occurred: {error}</p>;
    }
    return (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
                loadingComponent={LoadingComponent}
                errorComponent={ErrorComponent}
            >
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<Chatv2 slim={false} defaultPersona={defaultPersona} assistantName={assistantName} />} />
                        </Route>
                        <Route path="/code" element={<div></div>}></Route>
                        <Route path="/slim" element={<LayoutSlim />}>
                            <Route index element={<Chatv2 slim={true} defaultPersona={defaultPersona} assistantName={assistantName} />} />
                        </Route>
                        <Route path="/admin/adminHome" element={<Layout />}>
                            <Route index element={<AdminHome />} />
                        </Route>
                        <Route path="/admin/personamanagement" element={<Layout />}>
                            <Route index element={<PersonaManagement />} />
                        </Route>
                        <Route path="/admin/usermanagement" element={<Layout />}>
                            <Route index element={<UserManagement />} />
                        </Route>
                        <Route path="/admin/stylemanagement" element={<Layout />}>
                            <Route index element={<StyleManagement />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
