import { useNavigate } from "react-router-dom";
import "../../styles/globals.css";

const AdminHome: React.FC = (): JSX.Element => {
    const navigate = useNavigate();

    const handleClick = (page: string) => {
        navigate(`/admin/${page}`); // Replace '/path-to-redirect' with your desired path
    };

    const handleBackpageClick = () => {
        navigate("/"); // returns to home
    };

    return (
        <main className="flex flex-grow h-[calc(100vh-64px)] overflow-y-auto w-screen flex-col text-sm text-white dark:text-white bg-customGrey">
            <button
                className="absolute top-20 left-5 w-24 text-2xl bg-blueButton transition duration-150 bg-blueButton hover:bg-slate-300 hover:text-black text-white rounded py-1 px-2 focus:outline-none focus:ring-2 focus:ring-blue-300 z-10"
                onClick={() => handleBackpageClick()}
            >
                &#8592;
            </button>
            <div className="flex justify-center items-center h-full">
                {/* <div className="w-72 h-36 mr-8 bg-cardGrey flex flex-col justify-center items-center text-center p-4">
                    <div className="flex justify-center">
                        <button
                            className="h-12 w-48 transition duration-150 bg-blueButton hover:bg-slate-300 hover:text-black text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-300"
                            onClick={() => handleClick("styleManagement")}
                        >
                            Manage Style
                        </button>
                    </div>
                </div> */}

                <div className="w-72 h-36 mr-8 bg-cardGrey flex flex-col justify-center items-center text-center p-4">
                    <div className="flex justify-center">
                        <button
                            className="h-12 w-48 transition duration-150 bg-blueButton hover:bg-slate-300 hover:text-black text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-300"
                            onClick={() => handleClick("personaManagement")}
                        >
                            Manage Personas
                        </button>
                    </div>
                </div>

                <div className="w-72 h-36 bg-cardGrey flex flex-col justify-center items-center text-center p-4">
                    <div className="flex justify-center">
                        <button
                            className="h-12 w-48 transition duration-150 bg-blueButton hover:bg-slate-300 hover:text-black text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-300"
                            onClick={() => handleClick("userManagement")}
                        >
                            Manage Users
                        </button>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AdminHome;
